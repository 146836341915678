<template>
  <b-card>
    <b-table
      ref="table"
      :no-provider-sorting="true"
      :items="reports"
      :fields="tableColumns"
      aria-busy="true"
      class="position-relative"
      responsive
      primary-key="id"
      empty-text="No Reports found"
      show-empty
    >
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="deleteReport(item)">
            <div class="text-danger">
              <feather-icon icon="TrashIcon" />
              <span>
                Delete
              </span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'ReportsModal',
  props: {
    reports: { type: Array, default: () => [] },
  },
  data() {
    return {
      tableColumns: [
        { key: 'report' },
        { key: 'user.profile', label: 'username', formatter: v => `${v?.first_name} ${v?.last_name}` },
        'actions',
      ],
    }
  },
  setup() {
    const { successfulOperationAlert, confirmOperation } = handleAlerts()
    return { successfulOperationAlert, confirmOperation }
  },
  methods: {
    deleteReport(item) {
      this.confirmOperation().then(() => {
        this.$activities.delete(`/internalops/live-comments/report/${item.id}`).then(() => {
          this.successfulOperationAlert('Report is deleted successfully')
          this.$refs.table.refresh()
        })
      })
    },
  },
}
</script>
<style lang="scss">

</style>
