<template>
  <b-card>
    <b-table
      ref="table"
      :no-provider-sorting="true"
      :items="getReportedComments"
      :fields="tableColumns"
      aria-busy="true"
      class="position-relative"
      responsive
      primary-key="id"
      empty-text="No reported comments found"
      show-empty
    >
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
          class="actions"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            v-b-modal.reports-table-modal
          >
            <feather-icon icon="FileTextIcon" />
            <span
              @click="setCommentReports(item.reports)"
            >
              Reports
            </span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteRecord(getDeleteEndPoint(item.parent,item.parent_id),item.id)">
            <feather-icon icon="TrashIcon" />
            <span> Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import deleteComment from '@/common/compositions/common/deleteRecord'

export default {
  name: 'ReportedCommentsTable',
  props: {
    getReportedComments: { type: Function, default: () => [] },
  },
  data() {
    return {
      tableColumns: [
        { key: 'body', label: 'comment' },
        { key: 'user.profile', formatter: value => `${value?.first_name} ${value?.last_name}`, label: 'username' },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { deleteRecord, table } = deleteComment()
    return { deleteRecord, table }
  },
  methods: {
    getDeleteEndPoint(type, commentId = null) {
      if (type === 'comments') { return `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/live/${this.$route.params.id}/comments/` }
      return `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/live/${this.$route.params.id}/comments/${commentId}/reply/`
    },
    setCommentReports(reports) {
      this.$emit('update:reports', reports)
    },
  },
}
</script>
<style lang="">

</style>
