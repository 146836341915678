<template>
  <div>
    <h2 class="mb-2">
      Comments Reports
    </h2>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <reported-comments-table
      v-bind="{getReportedComments}"
      :reports.sync="reports"
    />

    <b-modal
      id="reports-table-modal"
      title="Reports"
      size="lg"
      hide-footer
    >
      <reports-table :reports="reports" />
    </b-modal>
  </div>
</template>
<script>
import ReportedCommentsTable from '@/common/components/Activities/ReportedCommentsTable.vue'
import ReportsTable from '@/common/components/Activities/ReportsTable.vue'

export default {
  name: 'LiveReportedComments',
  components: {
    ReportedCommentsTable,
    ReportsTable,
  },
  data() {
    return {
      comments: [],
      reports: [],
      loading: true,
      deleteCommentUrl: `/internalops/live/${this.$route.params.id}/comments/`,
    }
  },
  methods: {
    getReportedComments() {
      this.loading = true
      return this.$activities.post('/internalops/live-comments/report/abuse', {
        session: this.$route.params.id,
      }).then(res => {
        const reports = res.data.data
        return reports || []
      }).catch(() => []).finally(() => { this.loading = false })
    },
  },
}
</script>
<style lang="scss">

</style>
